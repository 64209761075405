// Modern design system mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin elevation($level: 1) {
  box-shadow: 0 #{$level * 2}px #{$level * 4}px rgba(0, 0, 0, 0.05);
}

@mixin smooth-transition {
  transition: all 0.3s ease-in-out;
}

@mixin responsive($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}
