@import '../mixins.scss';

// Modern interactive effects
.hover-lift {
  @include smooth-transition;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
}

.hover-scale {
  @include smooth-transition;
  &:hover {
    transform: scale(1.02);
  }
}

.glass-effect {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.07);
}

// Modern layout styles
.modern-container {
  @include flex-center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  
  @include responsive(768px) {
    padding: 3rem;
  }
}

.gradient-text {
  background: linear-gradient(45deg, #3B82F6, #8B5CF6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

// Modern grid layout
.modern-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 2rem;
}