@import '../../components/ui/modern-styles.scss';

.auth-input {
  .MuiOutlinedInput-root {
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    
    &:hover {
      transform: translateY(-1px);
    }
    
    &.Mui-focused {
      border-color: #2563EB;
      box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
    }
  }
}

.auth-link {
  color: #2563EB;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    color: #1D4ED8;
    text-decoration: underline;
  }
}

// Add smooth animations for form elements
.form-element {
  animation: fadeInUp 0.5s ease-out forwards;
  opacity: 0;
  transform: translateY(20px);
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Stagger animation delays
.form-element:nth-child(1) { animation-delay: 0.1s; }
.form-element:nth-child(2) { animation-delay: 0.2s; }
.form-element:nth-child(3) { animation-delay: 0.3s; }